<template>
  <div v-if="dialog && user">

    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            !user._id ? $t("title.create_user") : $t("title.update_user")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading"  text @click="validate">
              {{ !user._id ? $t("button.create") : $t("button.update") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="mt-5">
                <v-text-field
                  v-model="user.user_name"
                  :label="$t('input.user_name')"
                  :rules="!(user._id) ? rules.user_name : ''"
                  :disabled="user._id ? true : false"
                />
              </v-row>
              <v-row v-if="!user._id">
                <v-text-field
                  v-model="user.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('input.password')"
                  :rules="rules.password"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-row>
              <v-row v-if="userp && (userp.role_id == '611532b19c6e77ad0fd50c1c' || userp.role_id == '612a3fcc1a1fcead8871e822')">
                <!-- <v-select
                  v-model="user.currency_type"
                  :items="currencyTypeDev"
                  item-text="name"
                  item-value="currency_type"
                  :label="$t('input.chose_type')"
                ></v-select> -->
              </v-row>
            
              <v-row v-else>
                <v-select
                  :disabled="true"
                  v-model="userp.currency_type"
                  :items="currencyType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.chose_type')"
                ></v-select>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="user.account_info"
                  :label="$t('input.bank_account')"
                />
              </v-row>
              <v-row>
                <v-select
                  v-model="user.role_id"
                  :items="optionRoles"
                  item-text="child_role_name"
                  item-value="child_role_id"
                  :label="$t('input.user_role')"
                  :rules="rules.role_id"
                  @input="alert(user.role_id)"
                  :disabled="
                    (userp.role_id != '611532b19c6e77ad0fd50c1c' ||
                      userp.role_id != '612a3fcc1a1fcead8871e822') &&
                    user._id
                      ? true
                      : false
                  "
                />
              </v-row>
              
              <v-row v-if="user && (user.role_id == '612a40a51a1fcead8871e833' || user.role_id == '6375fe7c27dbbb022015c19d')">
                <v-combobox
                  v-model="user.company_ids"
                  :items="listCompany"
                  :label="$t('input.listAllCompany')"
                  multiple
                  chips
                  item-text="user_name"
                  item-value="_id"
                  persistent-hint
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item._id)"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      class="v-chip--select-multi"
                      @input="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text mr-1"
                        v-text="data.item.user_name.slice(0, 1).toUpperCase()"
                      />
                      {{ data.item.user_name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-row>
              <v-row v-if="user && user.role_id == '612a40a51a1fcead8871e833'">
                <v-combobox
                  v-model="user.module_ids"
                  :items="listModule"
                  :label="$t('input.listModule')"
                  :rules="rules.allow_module"
                  multiple
                  chips
                  item-text="name"
                  item-value="_id"
                  persistent-hint
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item._id)"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      class="v-chip--select-multi"
                      @input="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text mr-1"
                        v-text="data.item.name.slice(0, 1).toUpperCase()"
                      />
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-row>
              <v-row v-if="user && user.role_id == '6375fe7c27dbbb022015c19d'">
                <v-combobox
                  v-model="user.module_ids"
                  :items="listChildModule"
                  :label="$t('input.listModule')"
                  :rules="rules.allow_module"
                  multiple
                  chips
                  item-text="name"
                  item-value="_id"
                  persistent-hint
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item._id)"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      class="v-chip--select-multi"
                      @input="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text mr-1"
                        v-text="data.item.name.slice(0, 1).toUpperCase()"
                      />
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-row>
               <v-row v-if="user && (user.role_id != '612a40a51a1fcead8871e833' && user.role_id != '6375fe7c27dbbb022015c19d')">
                <v-combobox
                  v-model="user.allow_module"
                  :items="listModule"
                  :label="$t('input.listModule')"
                  :rules="rules.allow_module"
                  multiple
                  chips
                  item-text="name"
                  item-value="_id"
                  persistent-hint
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item._id)"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      class="v-chip--select-multi"
                      @input="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text mr-1"
                        v-text="data.item.name.slice(0, 1).toUpperCase()"
                      />
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-row>
              <v-row>
              <v-col lg="12" v-if="user && (user.role_id != '61829df5e64b1b1768832700' && user.role_id != '61829dfee64b1b1768832709')">
                <tr v-for="item in user.allow_module" :item="item" :key="item._id">
                  <td>
                    <label>{{item.name}}</label>
                  </td>
                  <td>
                    <v-text-field
                      class="pl-4"
                      solo
                      dense
                      :rules="rules.share"
                      v-model="item.share"
                      :value="0"
                    ></v-text-field>
                  </td>
                </tr>
              </v-col>
              </v-row>
              <v-row style="justify-content: right;">
                <v-checkbox
                  v-model="user.enable"
                  color="orange"
                  :label="$t('input.enable')"
                ></v-checkbox>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
     selectedChannel: {
      type: Array,
      default: () => {
        return [1,2,3,4];
      },
    },
    userp: {
      type: Object,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
      listCompany: {
      type: Array,
      default: null,
    },
    listModule: {
      type: Array,
      default: null,
    },
    listChildModule: {
      type: Array,
      default: null,
    },
    darkmode: {
      type: Boolean,
    },
    optionRoles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function() {
        return {
          user_name: [
            (v) => !!v || "Name is required",
            (v) => !/[^a-zA-Z0-9]/.test(v) || "ឈ្មោះ គណនីមិនអនុញ្ញាតបង្កើតជាមួយ សញ្ញាពិសេស ដកឃ្លា ឬ អក្សរខ្មែរ។"
          ],
          account_info: [(v) => !!v || "description is required"],
          balance: [(v) => !!v || "Order is required"],
          password: [(v) => !!v || "Password is required"],
          enable: [(v) => !!v || "Enable is required"],
          role_id: [(v) => !!v || "role is required"],
          share: [(v) => !(v && parseFloat(v) > 100 ) || 'Input must smaller than 100%'],
          allow_module: [(v) => !(v.length == 0) || "Module is required"]
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: function() {
        return {
          _id:null,
          password: 123456,
          enable: true,
          // balance: "",
        };
      },
    },
  },
  computed: {
    
    currencyType() {
      if (this.userp && this.userp.currency_type == 1)
        return [{ name: "លុយដុល្លា Usd", _id: 1 }]
      if (this.userp && this.userp.currency_type == 2)
        return [{ name: "លុយខ្មែរ Riel ", _id: 2 }]
      if (this.userp && this.userp.currency_type == 3)
        return [{ name: "លុយបាត Baht", _id: 3 }]
      else
        return [
          { _id: 1, name: "លុយដុល្លា - Usd" },
          { _id: 2, name: "លុយរៀល - Khmer" },
          // { _id: 3, name: "លុយបាត - Baht" },
        ];
    },
    currencyTypeDev() {
      return [
        { currency_type: 1, name: "លុយដុល្លា - Usd" },
        { currency_type: 2, name: "លុយរៀល - Khmer" },
        // { currency_type: 3, name: "លុយបាត - Baht" },
      ];
    },
    valid: {
      // getter
      get: function() {
        return this.isValidate;
      },
      // setter
      set: function(newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      showPassword: false,
      items: [
        { _id: 1, name: "លុយដុល្លា - Usd" },
        { _id: 2, name: "លុយរៀល - Khmer" },
        // { _id: 3, name: "លុយបាត - Baht" },
      ],
      optionChannelType:[
         {_id: 1, name: "បុស្តិ៍ទី ១"},
         {_id: 2, name: "បុស្តិ៍ទី ២"},
         {_id: 3, name: "បុស្តិ៍ទី ៣"},
         {_id: 4, name: "បុស្តិ៍ទី ៤"}
      ]
    };
  },
  
  watch: 
  {
    optionRoles: function() {},
    listCompany: function() {},
    listModule: function() {},
    listChildModule: function () {},
    user: function(newV){
      if (newV && newV.role_id == "612a40a51a1fcead8871e833")  this.$emit("handleListCompany");
      if(this.user && !this.user._id && !this.user.role_id){
      //  Level developer
        this.userp.role_id == "611532b19c6e77ad0fd50c1c" ?   this.user.role_id = "612a3fcc1a1fcead8871e822" :"";
      //  Level Company 
        this.userp.role_id ==   "612a3fcc1a1fcead8871e822" ? this.user.role_id = "61829d84e64b1b17688326a3" :""  
      //  Level Senior Dollar 
        this.userp.role_id ==   "61829d84e64b1b17688326a3" ? this.user.role_id = "61829dbbe64b1b17688326cb" :""  
        //  Level Master Dollar 
        this.userp.role_id ==   "61829dbbe64b1b17688326cb" ? this.user.role_id = "61829dd1e64b1b17688326df" :""  
        //  Level Agent Dollar 
        this.userp.role_id ==   "61829dd1e64b1b17688326df" ? this.user.role_id = "61829dfee64b1b1768832709" :"" 
          //  Level Senior Riel 
        this.userp.role_id ==   "61829d75e64b1b1768832699" ? this.user.role_id = "61829da2e64b1b17688326b7" :""  
        //  Level Master Riel 
        this.userp.role_id ==   "61829da2e64b1b17688326b7" ? this.user.role_id = "61829dc7e64b1b17688326d5" :""  
        //  Level Agent Riel 
        this.userp.role_id ==   "61829dc7e64b1b17688326d5" ? this.user.role_id = "61829df5e64b1b1768832700" :""  
          //  Level Senior Baht 
        this.userp.role_id ==   "61829d93e64b1b17688326ad" ? this.user.role_id = "61829db0e64b1b17688326c1" :""  
        //  Level Master Baht 
        this.userp.role_id ==   "61829db0e64b1b17688326c1" ? this.user.role_id = "61829de6e64b1b17688326f3" :""  
        //  Level Agent Baht 
        this.userp.role_id ==   "61829de6e64b1b17688326f3" ? this.user.role_id = "61829e05e64b1b1768832712" :""  
      }
    }
  },
  mounted(){
     if(this.userp && this.user && !this.user._id){
      //  Level developer
        this.userp.role_id == "611532b19c6e77ad0fd50c1c" ?   this.user.role_id = "612a3fcc1a1fcead8871e822" :"";
      //  Level Company 
        this.userp.role_id ==   "612a3fcc1a1fcead8871e822" ? this.user.role_id = "61829d84e64b1b17688326a3" :""  
      //  Level Senior Dollar 
        this.userp.role_id ==   "61829d84e64b1b17688326a3" ? this.user.role_id = "61829dbbe64b1b17688326cb" :""  
        //  Level Master Dollar 
        this.userp.role_id ==   "61829dbbe64b1b17688326cb" ? this.user.role_id = "61829dd1e64b1b17688326df" :""  
        //  Level Agent Dollar 
        this.userp.role_id ==   "61829dd1e64b1b17688326df" ? this.user.role_id = "61829dfee64b1b1768832709" :"" 
          //  Level Senior Riel 
        this.userp.role_id ==   "61829d75e64b1b1768832699" ? this.user.role_id = "61829da2e64b1b17688326b7" :""  
        //  Level Master Riel 
        this.userp.role_id ==   "61829da2e64b1b17688326b7" ? this.user.role_id = "61829dc7e64b1b17688326d5" :""  
        //  Level Agent Riel 
        this.userp.role_id ==   "61829dc7e64b1b17688326d5" ? this.user.role_id = "61829df5e64b1b1768832700" :""  
          //  Level Senior Baht 
        this.userp.role_id ==   "61829d93e64b1b17688326ad" ? this.user.role_id = "61829db0e64b1b17688326c1" :""  
        //  Level Master Baht 
        this.userp.role_id ==   "61829db0e64b1b17688326c1" ? this.user.role_id = "61829de6e64b1b17688326f3" :""  
        //  Level Agent Baht 
        this.userp.role_id ==   "61829de6e64b1b17688326f3" ? this.user.role_id = "61829e05e64b1b1768832712" :""  
      }
  },
  methods: {
    alert(item){
      if( item =="612a40a51a1fcead8871e833") {
        this.user.role_id = "612a40a51a1fcead8871e833"
        // console.log(this.user.role_id);
        }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.userp.role_id != "611532b19c6e77ad0fd50c1c" &&
        this.userp.role_id != "612a3fcc1a1fcead8871e822"
          ? (this.user.currency_type = this.userp.currency_type
            )
          : "";
        this.$emit("submit");
      }
    },
  },
};
</script>
